<template>
  <section v-if="showPage">
    <!--HEADER-->
    <b-overlay :show="!tripData" spinner-medium>
      <trip-view :trip-data="tripData" />
    </b-overlay>
    <b-row class="match-height">
      <b-col>
        <b-card class="map-airport">
          <b-overlay :show="markerLoaded" spinner-medium>
            <label class="search-autocomplete_map">
              <b-input-group>
                <GmapAutocomplete ref="searchGoogle" class="form-control" @place_changed="setPlace" />
                <b-input-group-append>
                  <b-button style="background: #fff" variant="outline-primary " @click="usePlace">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </label>
            <GmapMap ref="airportMap" :center="center" :zoom="8" style="width: 100%; height: 500px">
              <GmapMarker
                v-if="place"
                :position="{
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                }"
                label="★"
              />
              <cluster>
                <GmapMarker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :clickable="true"
                  :position="{ lat: marker.latitude, lng: marker.longitude }"
                  @click="openMarker(marker.id)"
                >
                  <gmap-info-window
                    :opened="openedMarkerID === marker.id"
                    @closeclick="
                      openMarker(null)
                      markerInfo = null
                    "
                  >
                    <h5>{{ marker.name }} - {{ marker.icaoCode }}</h5>
                    <div>
                      {{ marker.info }}
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <b-button class="d-flex mt-2" variant="outline-primary" @click="currentInfoMarker(marker)">
                        <font-awesome-icon class="mr-1" icon="info-circle" />
                        <span class="align-middle">{{ $t('action.see_more_info') }}</span>
                      </b-button>
                    </div>
                  </gmap-info-window>
                </GmapMarker>
              </cluster>
            </GmapMap>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <airport-slot
          v-if="markerInfo"
          id="airportSlot"
          ref="airportSlot"
          :marker-info="markerInfo"
          :other-leg-informations="otherLegInformations"
          :trip-leg.sync="tripLeg"
          @on-go-to-other-leg="val => goToOtherLeg(val)"
        />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import TripView from '@/views/trip/trip-view/TripView.vue'
import { fetchAirportsRequest } from '@/request/globalApi/requests/airportRequests'
import AirportSlot from './AirportSlot.vue'

export default {
  name: 'AirportMap',

  components: {
    TripView,
    // eslint-disable-next-line vue/no-unused-components
    'gmap-custom-marker': GmapCustomMarker,
    cluster: GmapCluster,
    AirportSlot,
  },
  data() {
    return {
      markerLoaded: false,
      tripData: {},
      moreInfo: false,
      openedMarkerID: null,
      markerInfo: null,
      center: {},
      position: {},
      markers: [],
      place: null,
      tripLeg: null,
      otherLegInformations: null,
      showPage: true,
    }
  },
  watch: {
    '$route.params.leg_id': {
      handler() {
        this.showPage = false
        this.APIFetchTrip()
        this.APIFetchAirports()
        this.markerInfo = null
        this.$nextTick(() => {
          this.showPage = true
        })
      },
    },
  },
  mounted() {
    this.APIFetchTrip()
    this.APIFetchAirports()
  },
  methods: {
    async APIFetchAirports() {
      this.markerLoaded = true
      const fetchTrip = await this.APIFetchTrip()
      this.getProximateAirports(fetchTrip.lat, fetchTrip.lng)
    },
    getProximateAirports(lat, lng) {
      fetchAirportsRequest({
        getAllItems: true,
        latitude: lat,
        longitude: lng,
      })
        .then(response => {
          const { airports } = response.data
          this.markers = airports
        })
        .finally(() => {
          this.markerLoaded = false
        })
    },
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: true })
      if (trip) {
        this.tripData = trip

        trip.tripSteps.forEach(tripStep => {
          if (Number(tripStep.id) === Number(this.$route.params.event_id)) {
            if (!this.getCurrentTripStep(tripStep.flightDeparture)) {
              this.getCurrentTripStep(tripStep.flightArrival)
            }

            this.otherLegInformations = {
              otherLegType: this.tripLeg.id === tripStep.flightDeparture.id ? 'arrival' : 'departure',
              otherLegId: this.tripLeg.id === tripStep.flightDeparture.id ? tripStep.flightArrival.id : tripStep.flightDeparture.id,
            }
          }
        })
        if (this.tripLeg.airport) {
          this.currentInfoMarker(this.tripLeg.airport)
          this.center = {
            lat: Number(this.tripLeg.airport.latitude),
            lng: Number(this.tripLeg.airport.longitude),
          }
        } else {
          this.center = {
            lat: Number(this.tripLeg.address.latitude),
            lng: Number(this.tripLeg.address.longitude),
          }
        }
      }
      return new Promise(resolve => {
        resolve(this.center)
      })
    },
    getCurrentTripStep(leg) {
      let currentTripStep = null
      Object.entries(leg).forEach(([key, val]) => {
        if (key === 'id' && val === Number(this.$route.params.leg_id)) {
          currentTripStep = leg
          this.tripLeg = currentTripStep

          return true
        }
        return false
      })
    },
    currentInfoMarker(marker) {
      this.markerInfo = marker
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    setPlace(place) {
      this.place = place
      this.usePlace()
    },
    usePlace() {
      if (this.place) {
        this.$refs.airportMap.$mapPromise.then(map => {
          map.panTo({ lat: this.place.geometry.location.lat(), lng: this.place.geometry.location.lng() })
        })
        this.markerLoaded = true
        this.getProximateAirports(this.place.geometry.location.lat(), this.place.geometry.location.lng())
      }
    },
    goToOtherLeg(val) {
      this.$route.params.leg_id = val
      this.APIFetchTrip()
      this.APIFetchAirports().then(() => {
        const rootEle = document.documentElement
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.$swal({
          title: `${this.$t('alert.timeline.validate_airport.change_leg.title')}`,
          text: `${this.$t('alert.timeline.validate_airport.change_leg.message')}${this.otherLegInformations.otherLegType}`,
          icon: 'success',
          confirmButtonText: this.$t('common.yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-dark ml-1',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>
<style scoped>
.map-airport {
  position: relative;
}

.search-autocomplete_map {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin: auto;
}
</style>
